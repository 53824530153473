export const getSoftware = (t) => [
    {
        title: "POS Software for Restaurants/Bars",
        description: "Manage storage, receipts, staff, admin settings, tables, and more with an easy-to-use interface.",
        icon: "🍽️", // Placeholder for icons or actual images
    },
    {
        title: "Hotel/Apartment Booking Software",
        description: "Handle room reservations, guest details, availability, and pricing in a seamless system.",
        icon: "🏨",
    },
    {
        title: "Accountant Software",
        description: "Organize client data, track taxes, manage salaries, and automate financial workflows.",
        icon: "📊",
    },
    {
        title: "Work Schedule Software + Soyal Card Reader",
        description: "Track employee shifts, attendance, and working hours with smart card integration.",
        icon: "🕒",
    },
    {
        title: "Hardware Repair Software",
        description: "Manage incoming/outgoing devices, client details, external repairs, and inventory tracking.",
        icon: "🛠️",
    },
      
]