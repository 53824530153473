import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/main/Home";
import About from "./pages/main/About";
import Services from "./pages/main/Services";
import Contact from "./pages/main/Contact";

import Security from "./pages/sub/services/Security";
import Fiscal from "./pages/sub/services/Fiscal";
import Repair from "./pages/sub/services/Repair";
import Software from "./pages/sub/services/Software";


function App() {
  const [loading, setLoading] = useState(false);

  return (
    <Router>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="animate-spin border-4 border-white border-t-transparent rounded-full w-20 h-20"></div>
        </div>
      )}
      <Navbar setLoading={setLoading}/>
      <div className="w-full">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/services/security" element={<Security />} />
          <Route path="/services/fiscal" element={<Fiscal />} />
          <Route path="/services/repair" element={<Repair />} />
          <Route path="/services/software" element={<Software />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
