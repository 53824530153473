import React, { useState } from "react";
import WhatsAppWidget from "../../components/WhatsAppWidget";
import { FaEnvelope, FaPhone, FaFacebook, FaInstagram, FaMapMarkerAlt } from "react-icons/fa";

const Contact = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, message } = formData;
    const subject = encodeURIComponent(`New message from ${name}`);
    const body = encodeURIComponent(`Name: ${name}\nEmail: ${email}\n\n${message}`);

    window.location.href = `mailto:office@xhumkar.com?subject=${subject}&body=${body}`;
  };

  return (
    <div className="min-h-screen bg-background text-textPrimary pt-24">
      {/* Contact Info Section */}
      <section className="text-center py-16 px-6 md:px-12 lg:px-24">
        <h2 className="text-4xl font-bold text-primary text-center mb-6">Get in Touch</h2>
        <p className="text-lg text-gray-600 text-center mb-8">
          Reach out to us for any inquiries, partnerships, or support.
        </p>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 text-center">
          <div className="p-6 bg-gray-100 rounded-lg flex flex-col items-center">
            <FaEnvelope className="text-4xl text-primary mb-2" />
            <h4 className="text-lg font-semibold">Email</h4>
            <p className="text-gray-700 text-primary">office@xhumkar.com</p>
          </div>

          <div className="p-6 bg-gray-100 rounded-lg flex flex-col items-center">
            <FaPhone className="text-4xl text-primary mb-2" />
            <h4 className="text-lg font-semibold">Mobile</h4>
            <p className="text-gray-700 text-primary">+389 71 222 810</p>
          </div>
          
          <div className="p-6 bg-gray-100 rounded-lg flex flex-col items-center">
            <FaPhone className="text-4xl text-primary mb-2" />
            <h4 className="text-lg font-semibold">Telephone</h4>
            <p className="text-gray-700 text-primary">+389 46 784 310</p>
          </div>

          <a href="https://www.facebook.com/Xhumkar" target="_blank" rel="noopener noreferrer"
                className="ring-1 ring-blue-400 p-6 bg-gray-100 rounded-lg flex flex-col items-center hover:ring-2 transition">
            <FaFacebook className="text-4xl text-primary mb-2" />
            <h4 className="text-lg font-semibold">Facebook</h4>
            <p className="text-gray-700 text-primary">/Xhumkar</p>
          </a>

          <a href="https://www.instagram.com/xhumkarcomputers" target="_blank" rel="noopener noreferrer"
                className="ring-1 ring-blue-400 p-6 bg-gray-100 rounded-lg flex flex-col items-center hover:ring-2 transition">
            <FaInstagram className="text-4xl text-primary mb-2" />
            <h4 className="text-lg font-semibold">Instagram</h4>
            <p className="text-gray-700 text-primary">@xhumkarcomputers</p>
          </a>

          <a href="https://maps.app.goo.gl/DpxU7UYnxsSLDvKq6" target="_blank" rel="noopener noreferrer"
                className="ring-1 ring-blue-400 p-6 bg-gray-100 rounded-lg flex flex-col items-center hover:ring-2 transition">
            <FaMapMarkerAlt className="text-4xl text-primary mb-2" />
            <h4 className="text-lg font-semibold">Location</h4>
            <p className="text-gray-700 text-primary">Struga, North Macedonia</p>
          </a>

        </div>
      </section>
        <WhatsAppWidget />
      {/* Contact Form Section */}
      <section className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h3 className="text-3xl font-bold text-primary text-center mb-6">Send Us a Message</h3>
        

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-gray-700 font-medium">Full Name</label>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-3 mt-2 border rounded-lg"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-3 mt-2 border rounded-lg"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Your Message</label>
            <textarea
              name="message"
              value={formData.message}
              placeholder="Your Message"
              onChange={handleChange}
              rows="4"
              className="w-full p-3 mt-2 border rounded-lg"
              required
            ></textarea>
          </div>
          <button type="submit" className="w-full bg-primary text-white p-3 rounded-lg font-medium hover:bg-primary-dark transition">
            Send Message
          </button>
        </form>
      </section>
    </div>
  );
};

export default Contact;
