import React from 'react';
import { FaWhatsapp } from "react-icons/fa";

const CTAContactUs = () => {
  return (
    <div className="text-center mt-16 mb-16">
        <p className="text-lg">Need a service? Get in touch with us today!</p>
        <div className="mt-4 flex justify-center gap-4">
          <a href="/contact" className="bg-secondary text-white py-3 px-6 rounded-full text-lg hover:bg-primary transition duration-300 flex items-center gap-2">
            Contact Us
          </a>
          <a href="https://wa.me/38971222810" className="bg-green-500 text-white py-3 px-6 rounded-full text-lg hover:bg-green-700 transition duration-300 flex items-center gap-2">
              <FaWhatsapp /> WhatsApp
          </a>
        </div>
    </div>
  )
}

export default CTAContactUs;

