import React from "react";
import { getServices } from "../../helpers/servicesData";

const ServicesPage = () => {
  const services = getServices();
  return (
    <div className="min-h-screen bg-background text-textPrimary pt-24">
      {/* Hero Section */}
      <section className="text-center py-16 px-6 md:px-12 lg:px-24">
        <h2 className="text-4xl font-bold text-primary">Our Services</h2>
        <p className="text-lg text-gray-600 mt-4">Explore our wide range of IT solutions designed for businesses and individuals.</p>
      </section>

      {/* Services Grid */}
      <div className="container mx-auto px-6 md:px-12 lg:px-24">
        {services.map((service, index) => (
          <div
            key={index}
            className={`flex flex-col md:flex-row items-center gap-16 mb-48`}
          >
            <div className="md:w-3/4">
              {service.icon}
              <h3 className="text-3xl font-semibold text-secondary mt-4">{service.title}</h3>
              <p className="text-lg text-gray-700 mt-4">{service.description}</p>
              <ul className="mt-4 text-gray-600">
                {service.details.map((detail, i) => (
                  <li key={i} className="flex items-start gap-2">
                    <span className="text-primary">✔</span>
                    {detail}
                  </li>
                ))}
              </ul>
            </div>
            <div className="md:w-1/4">
              <img src={service.img} alt={service.title} className="rounded-lg shadow-lg" />
            </div>
          </div>
        ))}
      </div>

      {/* FAQ Section */}
      <section className="bg-gray-100 py-16 mt-16 px-6 md:px-12 lg:px-24">
        <h2 className="text-3xl font-bold text-primary text-center">Frequently Asked Questions</h2>
        <div className="mt-8">
          <div className="mb-6">
            <h4 className="text-xl font-semibold text-secondary">How can I request a service?</h4>
            <p className="text-gray-700 mt-2">You can contact us via our <a href="/contact" className="text-primary font-semibold">Contact Page</a> or visit our store.</p>
          </div>
          <div className="mb-6">
            <h4 className="text-xl font-semibold text-secondary">Do you provide on-site support?</h4>
            <p className="text-gray-700 mt-2">Yes! We offer on-site services for businesses and homes.</p>
          </div>
          <div className="mb-6">
            <h4 className="text-xl font-semibold text-secondary">Do you sell hardware?</h4>
            <p className="text-gray-700 mt-2">Yes, we have a wide range of IT accessories and hardware available.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesPage;