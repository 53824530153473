import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi"; // Importing icons

const Navbar = ({ setLoading }) => {
  const navigate = useNavigate();
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);

  const handleNavigation = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
      setLoading(false);
    }, 300);
    setIsMobileOpen(false); // Close menu on mobile when a link is clicked
  };

  return (
    <nav className="bg-primary text-white fixed top-0 left-0 w-full z-50 shadow-md">
      <div className="flex items-center justify-between max-w-7xl mx-auto p-4 gap-48">
        
        {/* Left Side: Logo & Title */}
        <div 
          className="flex items-center space-x-4 cursor-pointer flex-1"
          onClick={() => handleNavigation("/")}
        >
          <img src="/images/xhumkar-logo.jpg" className="w-16 h-16" alt="Xhumkar Computers" />
          <h1 className="text-xl font-bold">Xhumkar Computers</h1>
        </div>

        {/* Right Side: Navigation Menu (Desktop) */}
        <div className="hidden md:flex items-center space-x-10 flex-2 justify-end">
          <button onClick={() => handleNavigation("/")} className="hover:text-secondary text-lg">
            Home
          </button>
          <button onClick={() => handleNavigation("/about")} className="hover:text-secondary text-lg">
            About
          </button>

          {/* Services Dropdown */}
          <div 
            className="relative group"
            onMouseEnter={() => setIsServicesOpen(true)}
            onMouseLeave={() => setIsServicesOpen(false)}
          >
            <button className="hover:text-secondary text-lg">
              Services ▼
            </button>

            {/* Dropdown Menu */}
            <div 
              className={`absolute left-0 w-48 bg-white text-gray-900 rounded-md shadow-lg overflow-hidden transition-opacity duration-200 ease-in-out 
              ${isServicesOpen ? "opacity-100 visible" : "opacity-0 invisible"}`}
            >
              <button onClick={() => handleNavigation("/services/software")} className="block px-4 py-2 text-left hover:bg-gray-200 w-full">
                Software
              </button>
              <button onClick={() => handleNavigation("/services/security")} className="block px-4 py-2 text-left hover:bg-gray-200 w-full">
                Security
              </button>
              <button onClick={() => handleNavigation("/services/fiscal")} className="block px-4 py-2 text-left hover:bg-gray-200 w-full">
                Fiscal
              </button>
              <button onClick={() => handleNavigation("/services/repair")} className="block px-4 py-2 text-left hover:bg-gray-200 w-full">
                Repair
              </button>
            </div>
          </div>

          <button onClick={() => handleNavigation("/contact")} className="hover:text-secondary text-lg">
            Contact
          </button>
        </div>

        {/* Mobile Menu Button (Right-aligned) */}
        <div className="md:hidden">
          <button onClick={() => setIsMobileOpen(!isMobileOpen)} className="text-white focus:outline-none">
            {isMobileOpen ? <FiX size={30} /> : <FiMenu size={30} />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden fixed top-0 right-0 h-full w-64 bg-primary text-white shadow-lg transform transition-transform duration-300 
        ${isMobileOpen ? "translate-x-0" : "translate-x-full"}`}
      >
        {/* Close Button */}
        <button onClick={() => setIsMobileOpen(false)} className="absolute top-4 right-4 text-white">
          <FiX size={30} />
        </button>

        <div className="mt-16 flex flex-col space-y-4 p-6">
          <button onClick={() => handleNavigation("/")} className="text-lg hover:bg-secondary p-2 rounded">
            Home
          </button>
          <button onClick={() => handleNavigation("/about")} className="text-lg hover:bg-secondary p-2 rounded">
            About
          </button>

          {/* Services Dropdown for Mobile */}
          <div className="flex flex-col group">
            <button onClick={() => setIsServicesOpen(!isServicesOpen)} className="text-lg group-hover:bg-secondary p-2 rounded">
              Services ▼
            </button>
            {isServicesOpen && (
              <div className="ml-4 flex flex-col space-y-2">
                <button onClick={() => handleNavigation("/services/software")} className="text-lg hover:bg-white hover:text-primary p-2 rounded">
                  Software
                </button>
                <button onClick={() => handleNavigation("/services/security")} className="text-lg hover:bg-white hover:text-primary p-2 rounded">
                  Security
                </button>
                <button onClick={() => handleNavigation("/services/fiscal")} className="text-lg hover:bg-white hover:text-primary p-2 rounded">
                  Fiscal
                </button>
                <button onClick={() => handleNavigation("/services/repair")} className="text-lg hover:bg-white hover:text-primary p-2 rounded">
                  Repair
                </button>
              </div>
            )}
          </div>

          <button onClick={() => handleNavigation("/contact")} className="text-lg hover:bg-secondary p-2 rounded">
            Contact
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
