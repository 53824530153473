import React from 'react';
import { FaChevronCircleRight } from "react-icons/fa";

const Hero = () => {
    return (
        <div className="min-h-screen">
            <section className="relative w-full h-screen bg-cover bg-center overflow-hidden" style={{ backgroundImage: 'url(/images/hero-section-1.webp)' }}>
                <div className="absolute inset-0 bg-black opacity-20"></div>
                <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-4">
                    <h1 className="text-4xl md:text-6xl font-bold mb-4">Empower Your Business with Our IT Solutions</h1>
                    <p className="text-lg md:text-2xl mb-8">We provide solutions for your problems and efficient way to manage your business since</p>
                    <a href="/services" className="bg-secondary text-white py-2 px-6 rounded-full text-lg hover:bg-white hover:text-secondary transition duration-300 flex items-center gap-2">Services<FaChevronCircleRight/></a>
                </div>
            </section>
        </div>

    );
}

export default Hero