import React from "react";
import { Link } from "react-router-dom";
import CTAContactUs from "../../../components/CTAContactUs";

const Fiscal = () => {
  return (
    <div className="min-h-screen bg-background text-textPrimary pt-24">
        {/* Header Section */}
        <div className="bg-primary text-white text-center py-16 px-4">
          <h1 className="text-4xl font-bold">Fiscal Devices</h1>
          <p className="mt-4 text-lg">
            Official partner of{" "}
            <Link 
              to="https://www.accent.mk"
              target="_blank"
              rel="noopener noreferrer"
              className="text-secondary font-semibold underline"
            >
              Accent.mk
            </Link>{" "}
            in the Southwest Region of North Macedonia (Ohrid, Struga & Debar).
          </p>
        </div>



      {/* Introduction */}
      <section className="rounded-lg text-primary max-w-4xl mx-auto py-12 px-6">
        <h2 className="text-3xl font-bold text-primary">What Are Fiscal Devices?</h2>
        <p className="mt-4 text-gray-700 text-lg leading-relaxed">
          Fiscal devices are electronic cash registers used to record sales and issue tax-compliant receipts. 
          In North Macedonia, all businesses must use fiscal devices to comply with government tax regulations. 
          These devices store transaction data securely and report it to the tax authorities.
        </p>
      </section>

      {/* Types of Fiscal Devices */}
      <section className="max-w-5xl mx-auto py-12 px-6">
        <h2 className="text-3xl font-bold text-primary">Types of Fiscal Devices</h2>
        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Fiscal Cash Registers */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-secondary">Fiscal Cash Registers</h3>
            <p className="mt-2 text-gray-700">
              Standalone cash registers that store sales data and print fiscal receipts. 
              Ideal for small businesses, retail stores, and kiosks.
            </p>
          </div>

          {/* Fiscal Printers */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-secondary">Fiscal Printers</h3>
            <p className="mt-2 text-gray-700">
              Advanced systems that combine software with fiscal printers. 
              Suitable for large businesses, restaurants, and hotels.
            </p>
          </div>

          {/* Mobile Fiscal Devices */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-secondary">Mobile Fiscal Devices</h3>
            <p className="mt-2 text-gray-700">
              Portable fiscal devices with built-in SIM cards for real-time tax reporting. 
              Great for delivery services and mobile businesses.
            </p>
          </div>
        </div>
      </section>

      {/* Required Documentation */}
      <section className="max-w-4xl mx-auto py-12 px-6">
        <h2 className="text-3xl font-bold text-primary">Required Documentation</h2>
        <p className="mt-4 text-lg leading-relaxed">
          To register a fiscal device in North Macedonia, businesses must provide the following:
        </p>
        <ul className="mt-4 space-y-2 list-disc list-inside">
          <li className="items-start gap-2">Company registration certificate</li>
          <li className="items-start gap-2">Tax Identification Number (TIN)</li>
          <li className="items-start gap-2">Valid business license</li>
          <li className="items-start gap-2">Owner’s ID or passport</li>
          <li className="items-start gap-2">Signed agreement with an authorized fiscal device provider</li>
        </ul>
      </section>

      <CTAContactUs />
    </div>
  );
};

export default Fiscal;
