import React from 'react';
import Hero from '../../components/Hero';
import ServicesSection from '../../components/ServicesSection';
//import Testimonials from '../../components/Testimonials';
//import FeaturedProjects from '../../components/FeaturedProjects';
import FAQ from '../../components/FAQ';
import CTAContactUs from '../../components/CTAContactUs';

const Home = () => {
  return (
    <>
      <Hero />
      <ServicesSection />
      {/* <FeaturedProjects /> */}
      {/* <Testimonials /> */}
      <FAQ />
      <CTAContactUs />
    </>
  )
}

export default Home