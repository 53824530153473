import { FaWhatsapp } from "react-icons/fa";

const WhatsAppWidget = () => {
  return (
    <a
    href="https://wa.me/+38971222810" // Replace with your number
    target="_blank"
    rel="noopener noreferrer"
    className="mx-auto flex max-w-sm items-center gap-x-2 fixed bottom-6 right-6 p-4 bg-green-500 text-white rounded-full shadow-lg hover:bg-green-600 transition-all"
    >
        <FaWhatsapp className="text-3xl size-8 shrink-0" />
        <span className="hidden md:inline font-semibold">Chat with Us</span>
    </a>
  );
};

export default WhatsAppWidget;
