import React from "react";
import { FaCashRegister, FaNetworkWired, FaLaptopCode, FaShoppingCart, FaPrint, FaCode } from "react-icons/fa";
import { MdApartment } from "react-icons/md";
import { GiSecurityGate } from "react-icons/gi";
import { PiSecurityCameraFill } from "react-icons/pi";

 export const getServices = (t) => [
  {
    title: "Software & POS Systems",
    icon: <FaCode className="text-4xl text-primary" />,
    description:
      "We develop customized software solutions for businesses, including POS systems for restaurants, bars, hotel booking systems, employee scheduling software, and accounting tools.",
    details: [
      "Tailored POS systems for hospitality businesses.",
      "Hotel booking management software for small hotels & rooms.",
      "Work schedule management tools for employees.",
      "Software solutions for accountants and financial professionals.",
    ],
    img: "/images/services/programming.png",
    link: "/services/software",
  },
  {
    title: "Fiscal Devices & Maintenance",
    icon: <FaCashRegister className="text-4xl text-primary" />, // Choose an appropriate icon
    description:
      "We provide full-service maintenance, repairs, and SIM card continuation for fiscal devices, ensuring seamless operations for businesses. Official partners of Accent.mk for the southwest region of North Macedonia.",
    details: [
      "SIM card continuation for fiscal devices.",
      "Crypto module continuation and maintenance.",
      "Memory save and replacement services.",
      "Fiscal device reports and troubleshooting.",
      `Official partner of Accent.mk for Struga, Ohrid, and Debar.`,
    ],
    img: "/images/services/cash-machine.png", // Replace with a relevant image
    link: "/services/fiscal",

  },
  {
    title: "CCTV Installation & Maintenance",
    icon: <PiSecurityCameraFill className="text-4xl text-primary" />,
    description:
      "Keep your business and home secure with professional CCTV camera installation and maintenance services.",
    details: [
      "High-quality security camera installation.",
      "Remote monitoring setup for real-time access.",
      "Annual maintenance & troubleshooting support.",
      "Integration with alarm systems for complete security.",
    ],
    img: "/images/services/cctv.png",
    link: "/services/security#cctv",

  },
  {
    title: "Alarm System Setup",
    icon: <GiSecurityGate className="text-4xl text-primary" />,
    description:
      "Our alarm system installations offer top-tier protection against unauthorized access and potential threats.",
    details: [
      "Burglar alarm system installation for homes & offices.",
      "Smart alarm systems with mobile alerts.",
      "Integration with CCTV for enhanced security.",
      "24/7 monitoring services available.",
    ],
    img: "/images/services/home-security.png",
    link: "/services/security#alarm",

  },
  {
    title: "Intercom Installation",
    icon: <MdApartment className="text-4xl text-primary" />,
    description:
      "Upgrade your building with a modern intercom system for secure and convenient communication.",
    details: [
      "Intercom system installation for apartments & offices.",
      "Hands-free audio & video intercom options.",
      "Maintenance & repair services available.",
      "Integration with access control systems.",
    ],
    img: "/images/services/intercom.png",
    link: "/services/security#intercom",

  },
  {
    title: "Network Configuration",
    icon: <FaNetworkWired className="text-4xl text-primary" />,
    description:
      "We provide network setup and optimization services to ensure your business operates smoothly and securely.",
    details: [
      "Router & switch setup for homes and businesses.",
      "Wireless network optimization & troubleshooting.",
      "Firewall and security configurations.",
      "Custom enterprise network solutions.",
    ],
    img: "/images/services/local-area-network.png",
    link: "/services/security",

  },
  {
    title: "PC & Laptop Repairs",
    icon: <FaLaptopCode className="text-4xl text-primary" />,
    description:
      "We repair all types of computers and laptops, from software issues to hardware replacements.",
    details: [
      "PC & laptop diagnostics and repair.",
      "OS installation & software troubleshooting.",
      "Custom-built PC assembly.",
      "Virus & malware removal services.",
    ],
    img: "/images/services/repairing.png",
    link: "/services/repair",

  },
  {
    title: "IT Accessories & Hardware Shop",
    icon: <FaShoppingCart className="text-4xl text-primary" />,
    description:
      "Shop for high-quality IT accessories, components, and peripherals at competitive prices.",
    details: [
      "Laptops, desktops, and accessories.",
      "Motherboards, RAM, and processors.",
      "Keyboards, mice, and networking equipment.",
      "Printers, scanners, and storage devices.",
    ],
    img: "/images/services/cart.png",
    link: "/services/shop",

  },
  {
    title: "Printer Repair Services",
    icon: <FaPrint className="text-4xl text-primary" />,
    description:
      "We provide fast and reliable printer repair services for all brands and models.",
    details: [
      "Laser & inkjet printer repairs.",
      "Toner & cartridge replacement.",
      "Paper jam troubleshooting & sensor fixes.",
      "Preventive maintenance for long-lasting performance.",
    ],
    img: "/images/services/printer.png",
    link: "/services/repair",

  },
 ]
    