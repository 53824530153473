import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-primary text-white py-10">
      <div className="container mx-auto px-6 md:px-12 lg:px-24">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
          
          {/* Logo & Company Info */}
          <div className="flex flex-col items-center md:items-start">
            <img src="/images/xhumkar-logo.jpg" alt="Xhumkar Computers" className="w-20 h-20 mb-3" />
            <h2 className="text-xl font-bold">Xhumkar Computers</h2>
            <p className="text-gray-300 mt-2">Your trusted IT solutions provider.</p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-semibold mb-3">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="hover:text-gray-300">Home</Link></li>
              <li><Link to="/about" className="hover:text-gray-300">About</Link></li>
              <li><Link to="/services" className="hover:text-gray-300">Services</Link></li>
              <li><Link to="/contact" className="hover:text-gray-300">Contact</Link></li>
            </ul>
          </div>

          {/* Contact & Social Media */}
          <div>
            <h3 className="text-lg font-semibold mb-3">Contact Us</h3>
            <p>Email: <a href="mailto:info@xhumkar.com" className="hover:text-gray-300">office@xhumkar.com</a></p>
            <p>Phone: <a href="tel:+38970123456" className="hover:text-gray-300">+389 71 222 810</a></p>

            {/* Social Media Links */}
            <div className="flex justify-center md:justify-start mt-3 space-x-4">
              <a href="https://facebook.com/Xhumkar" target="_blank" rel="noopener noreferrer" className="hover:text-secondary">
                <FaFacebookF size={20} />
              </a>
              <a href="https://instagram.com/xhumkarcomputers" target="_blank" rel="noopener noreferrer" className="hover:text-secondary">
                <FaInstagram size={20} />
              </a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-10 text-center border-t border-gray-500 pt-4">
          <p>&copy; {new Date().getFullYear()} Xhumkar Computers. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
