import React from "react";
import CTAContactUs from "../../../components/CTAContactUs";
import { FaNetworkWired, FaLaptop, FaPrint, FaWindows, FaMicrochip, FaTools } from "react-icons/fa";

const Repair = () => {
  return (
    <div className="min-h-screen bg-background text-textPrimary pt-24">
        {/* Header Section */}
        <div className="bg-primary text-white text-center py-16 px-4">
          <h1 className="text-4xl font-bold">Repair Services</h1>
          <p className="mt-4 text-lg">
            We repair and maintain <b>Laptops, PCs, Printers, UPS Systems, and more</b>.
          </p>
        </div>

      

      {/* Introduction Section */}
      <section className="rounded-lg text-primary max-w-4xl mx-auto py-12 px-6">
        <h2 className="text-3xl font-bold text-primary">Why Choose Us for Repairs?</h2>
        <p className="mt-4 text-lg leading-relaxed text-gray-700">
          We offer <b>fast, reliable, and affordable</b> repair services for individuals and businesses. 
          Whether it’s <b>hardware malfunctions, software issues, or routine maintenance</b>, our team is equipped to handle it.
        </p>
      </section>

      {/* Repair Categories */}
      <section className="max-w-5xl mx-auto py-12 px-6">
        <h2 className="text-3xl font-bold text-primary text-center">Our Repair Services</h2>
        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Laptop & PC Repairs */}
          <div className="bg-white p-6 rounded-lg shadow-md">
          <FaLaptop className="text-4xl text-primary mb-3" />
            <h3 className="text-xl font-semibold text-primary">Laptops & PCs</h3>
            <ul className="mt-3 list-disc list-inside text-gray-700">
              <li>Screen replacements & keyboard repairs</li>
              <li>Hard drive & SSD upgrades</li>
              <li>RAM upgrades & motherboard fixes</li>
              <li>Overheating issues & cooling solutions</li>
            </ul>
          </div>

          {/* Printer & UPS Repairs */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <FaPrint className="text-4xl text-primary mb-3" />
            <h3 className="text-xl font-semibold text-primary">Printers & UPS Systems</h3>
            <ul className="mt-3 list-disc list-inside text-gray-700">
              <li>Printer troubleshooting & ink system fixes</li>
              <li>Power supply & battery replacements</li>
              <li>Software driver installations</li>
              <li>General maintenance & cleaning</li>
            </ul>
          </div>

          {/* OS Installations & Backups */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <FaWindows className="text-4xl text-primary mb-3" />
            <h3 className="text-xl font-semibold text-primary">Operating System & Software</h3>
            <ul className="mt-3 list-disc list-inside text-gray-700">
              <li>Windows installations</li>
              <li>Software installations & configurations</li>
              <li>System backups & data recovery</li>
              <li>Virus removal & security solutions</li>
            </ul>
          </div>

          {/* Electronics Repairs */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <FaMicrochip className="text-4xl text-primary mb-3" />
            <h3 className="text-xl font-semibold text-primary">General Electronics Repairs</h3>
            <ul className="mt-3 list-disc list-inside text-gray-700">
              <li>Component-level repairs (chips, capacitors, etc.)</li>
              <li>Motherboard & power supply fixes</li>
              <li>Display panel & screen replacements</li>
              <li>Custom-built PC assembly & testing</li>
            </ul>
          </div>

          {/* Network & Cable Repairs */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <FaNetworkWired className="text-4xl text-primary mb-3" />
            <h3 className="text-xl font-semibold text-primary">Network & Connectivity Issues</h3>
            <ul className="mt-3 list-disc list-inside text-gray-700">
              <li>Network troubleshooting & slow connection fixes</li>
              <li>Ethernet cable replacements & WiFi signal improvement</li>
              <li>Router & modem configuration</li>
              <li>Office & home network setup</li>
            </ul>
          </div>

          {/* Software Troubleshooting */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <FaTools className="text-4xl text-primary mb-3" />
            <h3 className="text-xl font-semibold text-primary">Software Support</h3>
            <ul className="mt-3 list-disc list-inside text-gray-700">
              <li>Software crashes & error troubleshooting</li>
              <li>Data recovery & lost file retrieval</li>
              <li>Network setup & troubleshooting</li>
              <li>Performance optimization & updates</li>
            </ul>
          </div>
        </div>
      </section>

      <CTAContactUs />
    </div>
  );
};

export default Repair;
