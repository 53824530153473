import React from "react";
import CTAContactUs from "../../components/CTAContactUs";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="min-h-screen bg-background text-textPrimary pt-24">
      
      {/* Page Heading */}
      <div className="bg-primary text-white text-center py-16 px-4">
        <h1 className="text-4xl font-bold">About Us</h1>
        <p className="mt-4 text-lg">Discover our journey and what drives us forward.</p>
      </div>

      {/* History Section */}
      <div className="flex flex-col md:flex-row items-center gap-32 mb-24 mt-12 px-6 md:px-12 lg:px-24">
        <div className="md:w-1/2">
          <h3 className="text-2xl font-semibold text-primary mb-4">Our History</h3>
          <p className="text-gray-700 leading-relaxed">
            Established in 1994, Xhumkar Computers began as a humble venture creating business visit cards. 
            As technology evolved, so did we—expanding into <b>PC formatting, Windows installations (Windows 95, 98, XP), and early hardware repairs</b>.
          </p>
          <p className="text-gray-700 leading-relaxed mt-4">
            Over the years, we diversified our expertise, developing <b>custom software applications</b> to meet industry needs. 
            Our partnerships, including <Link
              to="https://www.accent.mk"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary font-bold underline"
              >
              
              <b><span className="cursor-pointer text-primary hover:text-secondary">Accent.mk </span></b>

              </Link>

            for fiscal devices, further strengthened our technological footprint. 
            Today, we provide a <b>wide range of IT services</b> including <b>CCTV & security systems, intercoms, website & web app development</b>, 
            ensuring businesses and individuals stay connected and secure in the digital age.
          </p>
        </div>
        <div className="md:w-1/2">
          <img src="/images/about/history_pc.jpg" alt="Company history Xhumkar Computers" className="w-100 h-50 rounded-lg shadow-md" />
        </div>
      </div>

      {/* Mission Section */}
      <div className="flex flex-col md:flex-row items-center gap-32 mb-24 px-6 md:px-12 lg:px-24">
        <div className="md:w-1/2">
          <h3 className="text-2xl font-semibold text-primary mb-4">Our Mission</h3>
          <p className="text-gray-700 leading-relaxed">
            At <b>Xhumkar Computers</b>, our mission is to <b>empower businesses and individuals</b> through cutting-edge  
            <b> IT solutions, software development, security systems, and web technologies</b>. 
          </p>
          <p className="text-gray-700 leading-relaxed mt-4">
            By combining <b>innovation, expertise, and reliability</b>, we ensure that our clients receive <b>secure, scalable, and future-proof solutions</b>. 
            We strive to remain at the <b>forefront of technology</b>, helping businesses grow and adapt in an ever-evolving digital landscape.
          </p>
        </div>
        <div className="md:w-1/2">
          <img src="/images/about/mission.jpg" alt="Our mission Xhumkar Computers" className="w-100 h-50 rounded-lg shadow-md" />
        </div>
      </div>

      {/* Core Values Section */}
      <div className="text-center mb-12 px-6 md:px-12 lg:px-24">
        <h3 className="text-2xl font-semibold text-primary mb-6">Our Core Values</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          
          <div className="p-6 bg-gray-100 rounded-lg shadow-md">
            <h4 className="text-xl font-semibold text-secondary">Integrity</h4>
            <p className="text-gray-700 mt-2">
              Building trust through honesty, transparency, and ethical business practices.
            </p>
          </div>
          <div className="p-6 bg-gray-100 rounded-lg shadow-md">
            <h4 className="text-xl font-semibold text-secondary">Customer Commitment</h4>
            <p className="text-gray-700 mt-2">
              Prioritizing our clients’ needs and delivering reliable IT solutions.
            </p>
          </div>
          <div className="p-6 bg-gray-100 rounded-lg shadow-md">
            <h4 className="text-xl font-semibold text-secondary">Growth & Adaptability</h4>
            <p className="text-gray-700 mt-2">
              Constantly learning and adapting to the ever-changing IT landscape.
            </p>
          </div>
        </div>
      </div>

      <CTAContactUs />
    </div>
  );
};

export default About;
