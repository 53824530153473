import React from "react";
import { HashLink } from 'react-router-hash-link';
import { getServices } from "../helpers/servicesData";


const ServicesSection = () => {
  const services = getServices();
  
  return (
    <div className="min-h-screen pt-20">
      {/* Hero Section */}
      <section className="text-center py-16 px-6 md:px-12 lg:px-24">
        <h2 className="text-4xl font-bold text-primary">Our Services</h2>
        <p className="text-lg text-gray-600 mt-4">Reliable IT solutions for businesses and individuals.</p>
      </section>
      
      {/* Services Grid */}
      <div className="container mx-auto px-6 md:px-12 lg:px-24">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <HashLink key={index} to={service.link} className="cursor-pointer hover:shadow-xl hover:shadow-secondary p-6 space-y-5 border border-dashed border-primary rounded-xl flex flex-col items-center text-center">
                {service.icon}
                <h3 className="text-2xl font-semibold text-primary mt-4">{service.title}</h3>
                <p className="text-gray-700 mt-2">{service.description}</p>
            </HashLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
