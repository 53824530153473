import CTAContactUs from "../../../components/CTAContactUs";
import { FaIntercom } from "react-icons/fa";
import { GiSecurityGate } from 'react-icons/gi';
import { PiSecurityCameraFill } from 'react-icons/pi';


const Security = () => {
  return (
    <div className="min-h-screen bg-background text-textPrimary pt-24">
      
        {/* <img
        //   src={securityImage}
          alt="Security Services"
          className="w-full h-72 object-cover brightness-75"
        /> */}

        {/* Hero Section */}
        <div className="bg-primary text-white text-center py-16 px-4">
          <h1 className="text-4xl font-bold">Security Solutions</h1>
          <p className="mt-4 text-lg">Protect your property with our advanced security systems.</p>
        </div>

      {/* CCTV Section */}
      <section className="container mx-auto px-6 py-24 min-h-screen" id="cctv">
        <div className="flex flex-col md:flex-row items-center gap-32 mb-16">
          {/* <img src="/images/services/cctv.png" alt="CCTV Installation" className="w-24 md:w-1/4 rounded-lg shadow-lg" /> */}
          <div className="md:w-3/4">
            <div className="flex items-center gap-3 mb-4">
              <PiSecurityCameraFill className="text-4xl text-primary" />
              <h2 className="text-3xl font-bold">CCTV Installation & Maintenance</h2>
            </div>
            <p className="text-lg text-gray-700">
              Ensure the safety of your home, office, or business with our <b>high-quality CCTV systems</b>.
              We offer <b>professional installation, configuration, and ongoing maintenance</b> to keep your security
              running smoothly.
            </p>
            <ul className="mt-4 space-y-2 list-disc list-inside">
              <li className="items-start gap-2">
                  <span className="text-primary"></span>
                  Indoor & outdoor surveillance cameras
                </li>
                <li className="items-start gap-2">
                  <span className="text-primary"></span>
                  Remote access via mobile & web applications
                </li>
                <li className="items-start gap-2">
                  <span className="text-primary"></span>
                  Night vision & motion detection features
                </li>
                <li className="items-start gap-2">
                  <span className="text-primary"></span>
                  Full HD & 4K recording options
                </li>  
                <li className="items-start gap-2">
                  <span className="text-primary"></span>
                  24/7 monitoring solutions
                </li>   
            </ul>
          </div>
        </div>

        {/* Image Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-10">
          <img src="/images/security/cctv_1.jpg" alt="CCTV" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="/images/security/cctv_2.jpg" alt="CCTV" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="/images/security/cctv_3.jpg" alt="CCTV" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="/images/security/cctv_4.jpg" alt="CCTV" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="/images/security/cctv_5.jpg" alt="CCTV" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="/images/security/cctv_6.jpg" alt="CCTV" className="w-full h-48 object-cover rounded-lg shadow-md" />
        </div>
        
      </section>

      {/* Alarm Systems Section */}
      <section className="bg-gray-200 py-24" id="alarm">
        <div className="container mx-auto px-6 flex flex-col md:flex-row items-center gap-32 mb-16">
          <div className="md:w-3/4">
            <div className="flex items-center gap-3 mb-4">
              <GiSecurityGate className="text-4xl text-primary" />
              <h2 className="text-3xl font-bold">Alarm Systems</h2>
            </div>
            <p className="text-lg text-gray-700">
              Protect your property from <b>intrusions and emergencies</b> with our <b>smart alarm systems</b>.
              We install and maintain <b>custom alarm solutions</b> for <b>homes, businesses, and industrial properties</b>.
            </p>
            <ul className="mt-4 space-y-2 list-disc list-inside">
              <li>Wireless & wired alarm system options</li>
              <li>Integration with CCTV & security apps</li>
              <li>Motion detectors & door/window sensors</li>
              <li>Real-time mobile alerts & notifications</li>
              <li>Professional maintenance & emergency support</li>
            </ul>
          </div>
          <img src="/images/services/home-security.png" alt="Alarm Systems" className="w-24 md:w-1/4 rounded-lg shadow-lg" />
        </div>

          {/* Image Grid */}
        {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-10">
          <img src="https://via.placeholder.com/300" alt="CCTV Work" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="https://via.placeholder.com/300" alt="CCTV Installation" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="https://via.placeholder.com/300" alt="CCTV Work" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="https://via.placeholder.com/300" alt="CCTV Installation" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="https://via.placeholder.com/300" alt="Security Cameras Setup" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="https://via.placeholder.com/300" alt="Security Cameras Setup" className="w-full h-48 object-cover rounded-lg shadow-md" />
        </div> */}
        
      </section>

      {/* Intercom Systems Section */}
      <section className="container mx-auto px-6 py-24" id="intercom">
        <div className="flex flex-col md:flex-row items-center gap-32 mb-16">
          <img src="/images/services/intercom.png" alt="Intercom Systems" className="w-24 md:w-1/4 rounded-lg shadow-lg" />
          <div className="md:w-3/4">
            <div className="flex items-center gap-3 mb-4">
              <FaIntercom className="text-4xl text-primary" />
              <h2 className="text-3xl font-bold">Intercom Systems</h2>
            </div>
            <p className="text-lg text-gray-700">
              Improve <b>communication and access control</b> with our <b>modern intercom systems</b>. Perfect for <b>apartment buildings,
              offices, and gated communities</b>, our solutions offer <b>audio & video intercoms</b> with remote access capabilities.
            </p>
            <ul className="mt-4 space-y-2 list-disc list-inside">
              <li>Video & audio intercom systems</li>
              <li>Secure access control for businesses & residences</li>
              <li>Remote control via mobile applications</li>
              <li>Integration with smart home & security systems</li>
              <li>Professional installation & support</li>
            </ul>
          </div>
        </div>

        {/* Image Grid
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-10">
          <img src="https://via.placeholder.com/300" alt="CCTV Work" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="https://via.placeholder.com/300" alt="CCTV Installation" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="https://via.placeholder.com/300" alt="CCTV Work" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="https://via.placeholder.com/300" alt="CCTV Installation" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="https://via.placeholder.com/300" alt="Security Cameras Setup" className="w-full h-48 object-cover rounded-lg shadow-md" />
          <img src="https://via.placeholder.com/300" alt="Security Cameras Setup" className="w-full h-48 object-cover rounded-lg shadow-md" />
        </div> */}

      </section>

      <CTAContactUs />
    </div>
  );
};

export default Security;
