import React, { useState } from 'react';

const faqs = [
  { question: "What IT services do you offer?", answer: "We provide software solutions, hardware repairs, CCTV installation, and more." },
  { question: "Do you offer remote support?", answer: "Yes! We provide online troubleshooting and IT support remotely." }
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  return (
    <section className="py-16 bg-gray-100 mt-12">
      <h2 className="text-4xl font-bold text-center text-primary">FAQs - Frequently Asked Questions</h2>
      <div className="container mx-auto px-6 md:px-12 lg:px-24 mt-8 space-y-6">
        {faqs.map((faq, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow-md cursor-pointer" onClick={() => setOpenIndex(openIndex === index ? null : index)}>
            <h3 className="text-lg font-semibold text-primary">{faq.question}</h3>
            {openIndex === index && <p className="text-gray-700 mt-2">{faq.answer}</p>}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
