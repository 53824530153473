import React from "react";
import { FaCode } from "react-icons/fa";
import { getSoftware } from "../../../helpers/softwareData";
import CTAContactUs from "../../../components/CTAContactUs";

const Software = () => {
  const software = getSoftware();

  return (
    <div className="min-h-screen bg-background text-textPrimary pt-24">
      {/* Hero Section */}
      <div className="bg-primary text-white text-center py-16 px-4">
        <h1 className="text-4xl font-bold">Software Solutions</h1>
        <p className="mt-4 text-lg">
          Powerful, reliable, and tailored software to help you manage your business with efficiency.
        </p>
      </div>

      {/* Software Listing */}
      <section className="container mx-auto px-6 py-24 min-h-screen">
        <div className="flex flex-col md:flex-row items-center gap-32 mb-16">
            {/* <img src="/images/services/cctv.png" alt="CCTV Installation" className="w-24 md:w-1/4 rounded-lg shadow-lg" /> */}
            <div className="md:w-3/4">
              <div className="flex items-center gap-3 mb-4">
                <FaCode className="text-4xl text-primary" />
                <h2 className="text-3xl font-bold">Software & POS Systems</h2>
              </div>
              <p className="text-lg text-gray-700">
              We develop customized software solutions for businesses, including POS systems for restaurants, bars, hotel booking systems, employee scheduling software, and accounting tools.
              </p>
              <ul className="mt-4 space-y-2 list-disc list-inside">      
                <li className="items-start gap-2">
                  <span className="text-primary"></span>
                  Tailored POS systems for restaurants / bars.
                </li>
                <li className="items-start gap-2">
                  <span className="text-primary"></span>
                  Hotel booking management software for small hotels & rooms.
                </li>
                <li className="items-start gap-2">
                  <span className="text-primary"></span>
                  Work schedule management tools for employees.
                </li>
                <li className="items-start gap-2">
                  <span className="text-primary"></span>
                  Software solutions for accountants and financial professionals.
                </li>        
              </ul>
            </div>
          </div>
        
        <h2 className="text-3xl font-bold text-center mb-10 mt-10">Our Software Package</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {software.map((software, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg text-center">
              <div className="text-5xl mb-4">{software.icon}</div>
              <h3 className="text-xl font-bold">{software.title}</h3>
              <p className="mt-2 text-gray-600">{software.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Business Growth Section */}
      <div className="bg-secondary text-white text-center py-16 px-4">
        <h2 className="text-3xl font-semibold">Why Choose Our Software?</h2>
        <p className="mt-4 text-lg">
          Our software solutions enhance efficiency, automate business processes, and simplify management, 
          allowing you to focus on what really matters.
        </p>
      </div>

      <CTAContactUs />
    </div>
  );
};

export default Software;
